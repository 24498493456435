// .homeContainer {
//   display: flex;
//   flex-direction: column;
//   height: 100vh;

//   a {
//     text-decoration: none;
//   }

//   .wrapper {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     position: relative;
//     width: 100%;
//     height: 50vh;
//     color: white;
//     background-color: black;

//     .content {
//       width: 85%;
//       padding-left: 5%;
//     }

//     img {
//       position: absolute;
//       top: 0;
//       left: 0;
//       z-index: -1;
//     }
//   }

//   .info {
//     background: url(../images/info.JPG);
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: bottom;
//     height: 80%;
//   }

//   .rondleiding {
//     background: url(../images/rondleiding.JPG);
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;
//     height: 100%;
//   }

//   @media screen and (min-width: 1000px) {
//     flex-direction: row;
//     font-size: 1.4em;
//     height: 70vh;
//     .wrapper{
//       height: 70vh;
//     }
//   }
// }

.homeContainerv2{
  background: url(../images/rondleiding.JPG);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  padding: 0 5%;

  div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 80%;
  }

  h1{
    color: white;
  }

  .header{
    text-align: center;
    padding: 5%;
    max-height: 10%;
  }

  div h1{
    font-size: 3em;
  }

  a{
    padding: 15px;
    background-color: white;
    color: black;
    text-decoration: none;
    width: 90%;
    font-weight: bold;
    font-size: 1.5em;
    margin-top:1em;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .inverted{
    color: white;
    background-color: black;
  }

  @media screen and (min-width: 1000px) {
    a{
      max-width: 30%;
      margin-right: 1em;
    }

    .actions{
      display: flex;
      flex-direction: row;
      height: 10vh;
      width: 100%;
      justify-content: flex-start;
    }
  }
}
