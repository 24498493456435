/* Google fonts*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;700&display=swap');

*{
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
    cursor: default;
}

.App{
    overflow: hidden;
    background-color: white;
}

html{
    background-color: white;
}

a {
  cursor: pointer !important;
}

.banner{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    background: url('../images/banner.JPG');
    background-repeat: no-repeat;
    background-position: 30%;
    background-size: cover;
    height: 10vh;
    font-size: 0.9em;
    opacity: 0.8;
}

.breadcrumb{
    display: flex;
    align-items: flex-start;
    padding: 5%;
    background-color: #e4e4e4;
    font-weight: bold;
    text-decoration: none;
    color: black;
}

 @media screen and (min-width: 1000px) {
    .breadcrumb{
      padding: 1%;
      padding-left: 5%;
    }
    .pc-ninja{
      display: none !important;
    }
  }

.noveen-dag{

  h2{
    font-weight: 400;
  }

  .audio-player{
    display: flex;
    justify-content: center;
    margin: 5% 0;
    width: 100%;
  }

  .react-audio-player {
    width: 100%;
  }

  margin-bottom: 2em;
}