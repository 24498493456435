.template-background{
  background-color: white;
}

.rondleidingContainer {
  background-color: white;
  color: black;
  padding: 10% 5% 0 5%;
  width: 100%;

  p {
    font-size: 1.1em;
  }

  .muted {
    opacity: 0.7;
  }

  .rondleiding-items{
    display: flex;
    flex-direction: column;
    .rondleiding-item{
      background-color: #e4e4e4;
      display: flex;
      margin: 2% 0;
      border-radius: 5px;
      
      p{
        width: 15%;
        text-align: center;
        border-right: solid 3px white;
        display: flex;
        align-items: center;
        justify-content: center;  
        padding: 2% 0;
        font-weight: bold;
      }

      a{
        width: 85%;
        text-align: left;
        color: black;
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 5% !important;
      }
    }
  }

  @media screen and (min-width: 1000px) {
    padding: 5% !important;
    a {
      padding: 2% 0 !important;
      cursor: pointer;
    }
    .rondleindingInfoContainer {
      width: 90%;
    }
    .rondleiding-items{
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;

      .rondleiding-item{
        flex-grow: 1;
        width: 30%;
        margin-left: 1.5%;
        margin-right: 1.5%;
      }

    }
  }
}

.item-container {
  padding: 10% 5% 0 5%;

  h1 {
    font-size: 1.7em;
  }

  .item-image-container {
    img {
      width: 100%;
    }

    display: flex;
    justify-content: center;
  }

  .item-navigation-control {
    font-weight: bold;
    margin: 5% 0 5% 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
    cursor: pointer;

    a {
      color: black;
      text-decoration: none;
      display: block;
      padding: 5%;
    }
  }

  .item-content {
    font-size: 1.4em;
  }

  .muted {
    opacity: 0.7;
    font-size: 1.3em;
  }

  @media screen and (min-width: 1000px) {
    display: flex;
    flex-direction: row;
    .item-container-image{
      width: 30%;
    }
    .item-container-content{
      width: 70%;
      padding-left: 5%;
    }
    margin-bottom: 5%;
  }
}

.glass-container {
  padding: 10% 5% 0 5%;

  h1 {
    font-size: 1.7em;
  }

  .item-image-container {
    img {
      width: 100%;
    }

    display: flex;
    justify-content: center;
  }

  .item-navigation-control {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
    cursor: pointer;

    a {
      color: black;
      text-decoration: none;
      display: block;
      padding: 5%;
    }
  }

  .item-content {
    font-size: 1.4em;
  }

  .muted {
    opacity: 0.7;
    font-size: 1.3em;
  }

  @media screen and (min-width: 1000px) {
    
    padding-top: 1%;

    .item-navigation-control{
      margin: 2%;
    }

    .GlassContainer{
      display: flex;
      flex-direction: column;

      .GlassContainerItem{
        display: flex;
        margin: 2% 0;

        .left{
          width: 30%;
        }
        .right{
          width: 70%;
          padding: 5%;
        }
      }
    }
  }
}
