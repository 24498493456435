.infoContainer {
  width: 100%;
  padding: 5%;

  p {
    font-size: 1.1em;
  }

  ul {
    list-style: none;
  }

  li {
    font-size: 1.1em;
    padding: 2% 0;

    a {
      color: #587291;
      text-decoration: none;
      font-weight: bold;
    }
  }

  .contactContainer {
    display: flex;
    flex-direction: column;
    padding: 1% 0;
    div {
      margin-bottom: 2%;
    }
    @media screen and (min-width: 1000px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: stretch;

      div {
        padding: 1% 2%;
        font-size: 16px !important;
        background-color: #f2f2f2;
        margin-right: 2%;
        width: content;
        min-width: 20%;
      }
    }
  }
}

.muted {
  opacity: 0.7;
}
